var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "div",
        { staticClass: "d-flex my-2 align-items-center" },
        [
          _c("span", { staticClass: "ms-0 me-3 fw-bold f14" }, [
            _vm._v(_vm._s(_vm.$t("Clinic information"))),
          ]),
          _c("v-divider"),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-content-between py-2 px-1 mb-4" },
        [
          _c("span", { staticClass: "f15" }, [
            _vm._v(_vm._s(_vm.$t("Clinic name"))),
          ]),
          _c("span", { staticClass: "primary--text f14" }, [
            _vm._v(_vm._s(_vm.doctor.clinic.name)),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-content-between py-2 px-1 mb-4" },
        [
          _c("span", { staticClass: "f15" }, [
            _vm._v(_vm._s(_vm.$t("Membership type"))),
          ]),
          _c("span", { staticClass: "primary--text f14 fw-bold" }, [
            _vm._v(_vm._s(_vm.memberRole)),
          ]),
        ]
      ),
      _vm.doctor.membership && _vm.doctor.membership.createDate
        ? _c(
            "div",
            { staticClass: "d-flex justify-content-between py-2 px-1 mb-4" },
            [
              _c("span", { staticClass: "f15" }, [
                _vm._v(_vm._s(_vm.$t("Membership date"))),
              ]),
              _c("span", { staticClass: "primary--text f14 fw-bold" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("moment")(
                      _vm.doctor.membership.createDate,
                      "dddd , jD jMMMM jYYYY"
                    )
                  ) + " "
                ),
              ]),
            ]
          )
        : _vm.doctor.membership && _vm.doctor.membership.updateDate
        ? _c(
            "div",
            { staticClass: "d-flex justify-content-between py-2 px-1 mb-4" },
            [
              _c("span", { staticClass: "f15" }, [
                _vm._v(_vm._s(_vm.$t("Membership date"))),
              ]),
              _c("span", { staticClass: "primary--text f14 fw-bold" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("moment")(
                      _vm.doctor.membership.updateDate,
                      "dddd , jD jMMMM jYYYY"
                    )
                  ) + " "
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm.doctor.membership && _vm.doctor.membership.memberFee
        ? _c(
            "div",
            { staticClass: "d-flex justify-content-between py-2 px-1 mb-4" },
            [
              _c("span", { staticClass: "f15" }, [
                _vm._v(_vm._s(_vm.$t("Clinic amount"))),
              ]),
              _c("span", { staticClass: "primary--text f14" }, [
                _vm._v(_vm._s(_vm.doctor.membership.memberFee) + "%"),
              ]),
            ]
          )
        : _c(
            "div",
            { staticClass: "d-flex justify-content-between py-2 px-1 mb-4" },
            [
              _c("span", { staticClass: "f15" }, [
                _vm._v(_vm._s(_vm.$t("Clinic amount"))),
              ]),
              _c("span", { staticClass: "primary--text f14" }, [
                _vm._v(_vm._s(_vm.$t("Not determined"))),
              ]),
            ]
          ),
      _c(
        "div",
        { staticClass: "d-flex my-2 align-items-center" },
        [
          _c("span", { staticClass: "ms-0 me-3 fw-bold f14" }, [
            _vm._v(_vm._s(_vm.$t("Secretary"))),
          ]),
          _c("v-divider"),
        ],
        1
      ),
      _vm.secretaries && _vm.secretaries.length > 0
        ? _c(
            "div",
            _vm._l(_vm.secretaries, function (member, index) {
              return _c(
                "div",
                { key: index },
                [
                  member.registered || member.doctorFullName.trim()
                    ? _c(
                        "v-card",
                        {
                          staticClass:
                            "d-flex align-center py-2 my-2 rounded-lg border",
                          attrs: { flat: "" },
                          on: {
                            click: function ($event) {
                              return _vm.openDrBottomSheet(member.doctorId)
                            },
                          },
                        },
                        [
                          _c(
                            "v-avatar",
                            { staticClass: "mx-3", attrs: { size: "72" } },
                            [
                              _c("v-img", {
                                attrs: {
                                  "lazy-src": require("@/assets/images/profile-circle.png"),
                                  src:
                                    member.pic != null
                                      ? member.pic
                                      : require("@/assets/images/profile.jpg"),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "f15 mx-1 grey--text text--darken-4",
                            },
                            [_vm._v(" " + _vm._s(member.doctorFullName) + " ")]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "v-card",
                        {
                          staticClass:
                            "d-flex align-center py-2 my-2 rounded-lg border border-red",
                          attrs: { flat: "" },
                        },
                        [
                          _c(
                            "v-avatar",
                            {
                              staticClass: "mx-3 border border-red",
                              attrs: { size: "72" },
                            },
                            [
                              _c("v-img", {
                                attrs: {
                                  "lazy-src": require("@/assets/images/profile-circle.png"),
                                  src:
                                    member.pic != null
                                      ? member.pic
                                      : require("@/assets/images/profile.jpg"),
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "f15 d-block mx-1 red--text red--darken-4 ltr",
                              },
                              [_vm._v(" " + _vm._s(member.mobile) + " ")]
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "f12 d-block mx-1 red--text red--darken-4",
                              },
                              [
                                _vm._v(
                                  " (" +
                                    _vm._s(_vm.$t("Awaiting confirmation")) +
                                    ") "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                ],
                1
              )
            }),
            0
          )
        : _c("span", { staticClass: "text-center f16 d-block mt-8" }, [
            _vm._v(" " + _vm._s(_vm.$t("No results found")) + " "),
          ]),
      _c("div", { staticClass: "d-flex my-2 align-items-center" }, [
        _c("span", { staticClass: "ms-0 me-3 fw-bold f14" }, [
          _vm._v(_vm._s(_vm.$t("Schedule a patient"))),
        ]),
      ]),
      _c(
        "v-list-item",
        { staticClass: "px-0 mx-0 d-none" },
        [
          _c(
            "v-list-item-icon",
            { staticClass: "ms-0 me-2" },
            [
              _c(
                "v-sheet",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center rounded-lg",
                  attrs: {
                    color: "#EEE8FF",
                    elevation: "0",
                    height: "36",
                    width: "36",
                  },
                },
                [
                  _c("iconly", {
                    attrs: { type: "bulk", name: "calendar", color: "#7E55F3" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item-content",
            {},
            [
              _c("v-list-item-title", { staticClass: "font-weight-bold f13" }, [
                _vm._v(_vm._s(_vm.$t("Emergency call"))),
              ]),
            ],
            1
          ),
          _c(
            "v-list-item-action",
            { staticClass: "d-flex flex-row align-items-center" },
            [
              _c("toggle-button", {
                attrs: {
                  value: _vm.emergencyCallStatus,
                  sync: true,
                  labels: {
                    checked: _vm.$t("Active"),
                    unchecked: _vm.$t("Inactive"),
                  },
                  "font-size": 12,
                  height: 35,
                  width: 85,
                  color: {
                    checked: "#20B1EA",
                    unchecked: "#CCCCCC",
                    disabled: "#CCCCCC",
                  },
                },
                on: { change: _vm.onChangeEmergencyCallStatus },
              }),
              _c("iconly", {
                staticClass: "ms-2 me-0",
                attrs: { type: "light", name: "arrow-left2", color: "#757575" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-list-item",
        { staticClass: "px-0 mx-0" },
        [
          _c(
            "v-list-item-icon",
            { staticClass: "ms-0 me-2" },
            [
              _c(
                "v-sheet",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center rounded-lg",
                  attrs: {
                    color: "#EEE8FF",
                    elevation: "0",
                    height: "36",
                    width: "36",
                  },
                },
                [
                  _c("iconly", {
                    attrs: { type: "bulk", name: "calendar", color: "#7E55F3" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item-content",
            [
              _c("v-list-item-title", { staticClass: "font-weight-bold f13" }, [
                _vm._v(_vm._s(_vm.$t("Online"))),
              ]),
            ],
            1
          ),
          _c(
            "v-list-item-action",
            { staticClass: "d-flex flex-row align-items-center" },
            [
              _c("toggle-button", {
                attrs: {
                  value: _vm.onlineCallStatus,
                  sync: true,
                  labels: {
                    checked: _vm.$t("Active"),
                    unchecked: _vm.$t("Inactive"),
                  },
                  "font-size": 12,
                  height: 35,
                  width: 85,
                  color: {
                    checked: "#20B1EA",
                    unchecked: "#CCCCCC",
                    disabled: "#CCCCCC",
                  },
                },
                on: { change: _vm.onChangeOnlineCallStatus },
              }),
              _c("iconly", {
                staticClass: "ms-2 me-0",
                attrs: { type: "light", name: "arrow-left2", color: "#757575" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-list-item",
        { staticClass: "px-0 mx-0" },
        [
          _c(
            "v-list-item-icon",
            { staticClass: "ms-0 me-2" },
            [
              _c(
                "v-sheet",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center rounded-lg",
                  attrs: {
                    color: "#EEE8FF",
                    elevation: "0",
                    height: "36",
                    width: "36",
                  },
                },
                [
                  _c("iconly", {
                    attrs: { type: "bulk", name: "calendar", color: "#7E55F3" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item-content",
            [
              _c("v-list-item-title", { staticClass: "font-weight-bold f13" }, [
                _vm._v(_vm._s(_vm.$t("Telephone"))),
              ]),
            ],
            1
          ),
          _c(
            "v-list-item-action",
            { staticClass: "d-flex flex-row align-items-center" },
            [
              _c("toggle-button", {
                attrs: {
                  value: _vm.phoneCallStatus,
                  sync: true,
                  labels: {
                    checked: _vm.$t("Active"),
                    unchecked: _vm.$t("Inactive"),
                  },
                  "font-size": 12,
                  height: 35,
                  width: 85,
                  color: {
                    checked: "#20B1EA",
                    unchecked: "#CCCCCC",
                    disabled: "#CCCCCC",
                  },
                },
                on: { change: _vm.onChangePhoneCallStatus },
              }),
              _c("iconly", {
                staticClass: "ms-2 me-0",
                attrs: { type: "light", name: "arrow-left2", color: "#757575" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("BshDoctor", { ref: "drBottomSheet", attrs: { fromDoctorApp: true } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }